<template>
    <v-row class="justify-center align-center mt-8 mb-16">
        <v-card width="1100px" elevation="3" class="pa-8" light>
            <v-row>
                <v-col cols="12" md="6" lg="6" xl="6" class="pl-0 pl-md-8 pl-lg-8 pl-xl-8">
                    <v-row>
                        <v-col cols="5">
                            <v-subheader class="sectionHeading pt-4" style="color: #020101;">
                                وزن خالص طلا
                            </v-subheader>
                        </v-col>
                        <v-col cols="7">
                            <v-text-field
                                    :value="weight" v-model="weight"
                                    suffix="گرم" color="mellowTwo"
                                    class="fieldFlatBorder" flat outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6" lg="6" xl="6" class="pr-0 pr-md-8 pr-lg-8 pr-xl-8 pl-0">
                    <v-row>
                        <v-col cols="5">
                            <v-subheader class="sectionHeading pt-4" style="color: #020101;">اجرت ساخت</v-subheader>
                        </v-col>
                        <v-col cols="7">
                            <v-text-field
                            :value="buildFee" v-model="buildFee"
                            suffix="%" color="mellowTwo"
                            class="fieldFlatBorder" flat outlined
                            ></v-text-field>
                            <!--<VuetifyMoney v-model="buildFee"-->
                                          <!--v-bind:outlined="outlined"-->
                                          <!--v-bind:color="mellowTwo"-->
                                          <!--v-bind:flat="flat"-->
                                          <!--v-bind:options="optionsBuildFee"/>-->
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6" lg="6" xl="6" class="pl-0 pl-md-8 pl-lg-8 pl-xl-8">
                    <v-row>
                        <v-col cols="5">
                            <v-subheader class="sectionHeading pt-4" style="color: #020101;">سود</v-subheader>
                        </v-col>
                        <v-col cols="7">
                            <v-text-field
                                    :value="profit" v-model="profit"
                                    suffix="%" color="mellowTwo"
                                    class="fieldFlatBorder" flat outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6" lg="6" xl="6" class="pr-0 pr-md-8 pr-lg-8 pr-xl-8 pl-0">
                    <v-row>
                        <v-col cols="5">
                            <v-subheader class="sectionHeading pt-4" style="color: #020101;">مالیات</v-subheader>
                        </v-col>
                        <v-col cols="7">
                            <v-text-field
                                    :value="tax" v-model="tax"
                                    suffix="%" color="mellowTwo"
                                    class="fieldFlatBorder" flat outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6" lg="6" xl="6" class="pl-0 pl-md-8 pl-lg-8 pl-xl-8">
                    <v-row>
                        <v-col cols="5">
                            <v-subheader class="sectionHeading pt-4" style="color: #020101;">ملحقات</v-subheader>
                        </v-col>
                        <v-col cols="7">
                            <!--<v-text-field-->
                            <!--:value="attachments" v-model="attachments"-->
                            <!--hint="چرم، سنگ و ..." suffix="تومان" color="mellowTwo"-->
                            <!--class="fieldFlatBorder" flat outlined-->
                            <!--&gt;</v-text-field>-->

                            <VuetifyMoney v-model="attachments"
                                          v-bind:outlined="outlined"
                                          v-bind:color="mellowTwo"
                                          v-bind:flat="flat"
                                          v-bind:hint="hint"
                                          v-bind:options="options"/>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6" lg="6" xl="6" class="pr-0 pr-md-8 pr-lg-8 pr-xl-8 pl-0">
                    <v-row>
                        <v-col cols="5">
                            <v-subheader class="sectionHeading pt-4" style="color: #020101;">قیمت لحظه ای طلا
                            </v-subheader>
                        </v-col>
                        <v-col cols="7">
                            <!--<v-text-field-->
                            <!--:value="goldPrice" v-model="goldPrice" suffix="تومان"-->
                            <!--color="mellowTwo"-->
                            <!--class="fieldFlatBorder" flat outlined-->
                            <!--&gt;</v-text-field>-->

                            <VuetifyMoney v-model="goldPrice"
                                          v-bind:outlined="outlined"
                                          v-bind:color="mellowTwo"
                                          v-bind:flat="flat"
                                          v-bind:options="options"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <!--results-->
            <v-row class=" justify-center align-center mt-8">
                <v-card color="backgroundThree" width="1050px" elevation="0">
                    <v-row class="pt-4">
                        <v-col cols="6" class="pr-1 pr-md-16 pr-lg-16 pr-xl-16">
                            <v-card-title class="formulaHeading">قیمت نهایی</v-card-title>

                            <v-card-subtitle class="formulaSubHeading">قیمت طلا + اجرت ساخت + سود + مالیات
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="6" class="pl-1 pl-md-16 pl-lg-16 pl-xl-16">
                            <v-card-title class="formulaPrice pt-6">{{finalPrice}} تومان</v-card-title>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pt-4">
                        <v-col cols="6" class="pr-1 pr-md-16 pr-lg-16 pr-xl-16">
                            <v-card-title class="formulaHeading">قیمت طلا محصول</v-card-title>

                            <v-card-subtitle class="formulaSubHeading">وزن ✕ نرخ روز طلا
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="6" class="pl-1 pl-md-16 pl-lg-16 pl-xl-16">
                            <v-card-title class="formulaPrice pt-6">{{productGoldPrice}} تومان</v-card-title>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pt-4">
                        <v-col cols="6" class="pr-1 pr-md-16 pr-lg-16 pr-xl-16">
                            <v-card-title class="formulaHeading">اجرت ساخت</v-card-title>

                            <v-card-subtitle class="formulaSubHeading">قیمت طلا ✕ درصد اجرت
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="6" class="pl-1 pl-md-16 pl-lg-16 pl-xl-16">
                            <v-card-title class="formulaPrice pt-6">{{productBuildFee}} تومان</v-card-title>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pt-4">
                        <v-col cols="6" class="pr-1 pr-md-16 pr-lg-16 pr-xl-16">
                            <v-card-title class="formulaHeading">سود فروش طلا</v-card-title>

                            <v-card-subtitle class="formulaSubHeading">(قیمت طلا + اجرت ساخت) ✕ درصد سود
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="6" class="pl-1 pl-md-16 pl-lg-16 pl-xl-16">
                            <v-card-title class="formulaPrice pt-6">{{productProfit}} تومان</v-card-title>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pt-4">
                        <v-col cols="6" class="pr-1 pr-md-16 pr-lg-16 pr-xl-16">
                            <v-card-title class="formulaHeading">مالیات محصول</v-card-title>

                            <v-card-subtitle class="formulaSubHeading">(سود فروش طلا + اجرت ساخت) ✕ درصد مالیات
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="6" class="pl-1 pl-md-16 pl-lg-16 pl-xl-16">
                            <v-card-title class="formulaPrice pt-6">{{productTax}} تومان</v-card-title>
                        </v-col>
                    </v-row>
                </v-card>
            </v-row>
        </v-card>
    </v-row>
</template>

<script>
    import axios from 'axios';
    import cheerio from 'cheerio';
    import VuetifyMoney from "./VuetifyMoney";

    export default {
        name: "Calculator",
        components: {VuetifyMoney},
        data() {
            return {
                goldPrice: 0,
                weight: '',
                buildFee: '20',
                profit: 7,
                tax: 9,
                attachments: 0,
                finalPrice: 0,
                productGoldPrice: 0,
                productBuildFee: 0,
                productProfit: 0,
                productTax: 0,
                outlined: true,
                flat: true,
                hint: "چرم، سنگ و ...",
                options: {
                    locale: "pt-BR",
                    prefix: "",
                    suffix: "تومان",
                    length: 15,
                    precision: 0
                },
                optionsBuildFee: {
                    locale: "pt-BR",
                    prefix: "",
                    suffix: "%",
                    length: 15,
                    precision: 0
                },
            }
        },
        // mounted() {
        // this.getWebsiteData();
        // },
        beforeUpdate() {
            this.getFinalPrice();
        },
        methods: {
            getWebsiteData() {
                let self = this;
                // let url = ' http://calc.talamin.com/webservice/price_live.php?new=1&demo=1'; // url we get data from
                let url = 'http://localhost:8080/webservice/price_live.php?new=1&demo=1'; // url we get data from
                let dataArray = [];                        // we put data in this array
                axios.get(url).then((response) => {
                    let html = response.data;
                    let $ = cheerio.load(html);

                    $("tr td.text-left").each(function () {
                        const price = $(this).find('a#gold_18k').text();
                        dataArray.push({
                            'price': price
                        })
                    });
                    self.goldPrice = dataArray[0];
                });
            },
            getFinalPrice() {
                // const goldPrice = this.goldPrice.price.replaceAll(",", "");
                // const sumPrice = +goldPrice + +10000;
                const sumPrice = parseFloat(this.goldPrice);
                const weight = parseFloat(this.weight);
                this.profit = parseFloat(this.profit);
                const attachments = this.attachments;

                if (this.buildFee > 99) {
                    const phase1 = sumPrice * (1 + (this.profit / 100));
                    const phase2 = phase1 - sumPrice;
                    const phase3 = phase2 * (1 + (this.tax / 100));
                    const phase4 = +phase3 + +sumPrice;
                    const phase5 = phase4 * weight;
                    const phase6 = (this.buildFee * (1 + (this.profit / 100)) * (1 + (this.tax / 100)));
                    const phase7 = +phase5 + +phase6;

                    this.finalPrice = +phase7 + +attachments;
                } else {
                    const phase1 = ((sumPrice * (1 + (this.buildFee / 100))) * (1 + (this.profit / 100)));
                    const phase2 = phase1 - sumPrice;
                    const phase3 = phase2 * (1 + (this.tax / 100));
                    const phase4 = +phase3 + +sumPrice;
                    const phase5 = phase4 * weight;

                    this.finalPrice = +phase5 + +attachments;
                }

                this.finalPrice = Math.round(this.finalPrice);
                this.finalPrice = (this.finalPrice).toLocaleString('en-US');

                let productGoldPrice = 0;
                let productBuildFee = 0;
                let productProfit = 0;

                if (this.buildFee > 99) {
                    this.productGoldPrice = 0
                } else {
                    this.productGoldPrice = sumPrice * weight;
                    productGoldPrice = this.productGoldPrice;
                    this.productGoldPrice = Math.round(productGoldPrice);
                    this.productGoldPrice = (this.productGoldPrice).toLocaleString('en-US');
                }

                if (this.buildFee > 99) {
                    this.productBuildFee = 0;
                } else {
                    this.productBuildFee = productGoldPrice * (this.buildFee / 100);
                    productBuildFee = this.productBuildFee;
                    this.productBuildFee = Math.round(productBuildFee);
                    this.productBuildFee = (this.productBuildFee).toLocaleString('en-US');
                }
                if (this.buildFee > 99) {
                    this.productProfit = 0;
                } else {
                    this.productProfit = (productGoldPrice + productBuildFee) * (this.profit / 100);
                    productProfit = this.productProfit;
                    this.productProfit = Math.round(productProfit);
                    this.productProfit = (this.productProfit).toLocaleString('en-US');
                }

                if (this.buildFee > 99) {
                    this.productTax = 0;
                } else {
                    this.productTax = (productProfit + productBuildFee) * (this.tax / 100);
                    this.productTax = Math.round(this.productTax);
                    this.productTax = (this.productTax).toLocaleString('en-US');
                }
            }
        },

    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Harmattan');

    .formulaHeading {
        font-family: Harmattan;
        font-size: 25px;
        font-weight: 500;
    }

    .formulaSubHeading {
        font-family: Harmattan;
        font-weight: 500;
        color: #1E1F26;
    }

    .formulaPrice {
        font-family: Harmattan;
        font-size: 28px;
        font-weight: 500;
        float: left;
    }

    @media only screen and (max-device-width: 400px) {
        .formulaPrice {
            font-family: Harmattan;
            font-size: 24px;
            font-weight: 500;
            float: left;
        }
    }


    .sectionHeading {
        font-family: Harmattan;
        font-size: 20px;
        font-weight: 500;
    }

    .fieldFlatBorder {
        border-radius: 0;
    }

    .buttonTextStyle {
        font-size: 24px;
        font-family: Harmattan;
        font-weight: 500;
    }

    /*.theme--light.v-input--is-disabled input {*/
    /*color: #000;*/
    /*}*/

    /*.theme--light.v-input--is-disabled {*/
    /*color: #000;*/
    /*}*/
</style>
