<template>
    <v-app-bar app color="#fff" flat height="120px" elevation="1">
        <!--<v-row>-->
        <!--<div class="toolbar_items">-->
        <!--<ul>-->
        <!--<li>-->
        <!--<router-link to="/category" class="linkStyle">خانه</router-link>-->
        <!--</li>-->
        <!--<li>-->
        <!--<router-link to="/Channels" class="linkStyle">فروشگاه</router-link>-->
        <!--</li>-->
        <!--<li>-->
        <!--<router-link to="/store" class="linkStyle">درباره طلامین</router-link>-->
        <!--</li>-->
        <!--<li>-->
        <!--<router-link to="/blog" class="linkStyle">سوالات متدوال</router-link>-->
        <!--</li>-->
        <!--<li>-->
        <!--<router-link to="/hom" class="linkStyle">ارتباط با ما</router-link>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</div>-->

        <!--<v-toolbar flat>-->
        <!--<v-toolbar-items class="hidden-sm-and-down">-->
        <!--<v-btn-->
        <!--v-for="item in menu"-->
        <!--:key="item.icon"-->
        <!--:to="item.link" x-large-->
        <!--flat text class="linkStyle pt-12"-->
        <!--&gt;{{ item.title }}-->
        <!--</v-btn>-->
        <!--</v-toolbar-items>-->
        <!--<v-menu class="hidden-md-and-up">-->
        <!--<v-toolbar-side-icon slot="activator"></v-toolbar-side-icon>-->
        <!--<v-list>-->
        <!--<v-list-tile v-for="item in menu" :key="item.icon">-->
        <!--<v-list-tile-content>-->
        <!--<v-list-tile-title>{{ item.title }}</v-list-tile-title>-->
        <!--</v-list-tile-content>-->
        <!--</v-list-tile>-->
        <!--</v-list>-->
        <!--</v-menu>-->
        <!--</v-toolbar>-->
        <!--</v-row>-->
        <v-row class="align-center justify-center pt-8 mt-0 pb-0 ">
            <v-img class="logo" alt="طلامین گالری" src="../assets/logo.png"
                   contain width="85" height="85"
            ></v-img>
        </v-row>
    </v-app-bar>
</template>

<script>
    export default {
        name: "Header",
        data() {
            return {
                menu: [
                    {link: 'home', title: 'خانه'},
                    {link: 'info', title: 'فروشگاه'},
                    {link: 'warning', title: 'درباره طلامین'},
                    {link: 'warning', title: 'سوالات متدوال'},
                    {link: 'warning', title: 'ارتباط با ما'}
                ]
            }
        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Harmattan');

    .toolbar_items ul {
        list-style: none;
        float: right;
        display: flex;
        padding: 60px 20px 0 0;
    }

    .toolbar_items li {
        padding: 0px 16px;
    }

    .toolbar_items li a {
        display: inline-block;
        text-decoration: none;
        color: #fff;
        position: relative;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        padding: 15px;
    }

    .toolbar_items li a:after {
        content: "";
        position: absolute;
        height: 2px;
        background-color: white;
        width: 0%;
        left: 50%;
        bottom: 0;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
        transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
    }

    .toolbar_items li a:hover:after {
        width: 100%;
    }

    .logo {
        top: -30px;
    }

    .linkStyle {
        font-family: Harmattan;
        font-size: 25px;
    }
</style>
