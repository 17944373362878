<template>
    <v-card flat width="100%" class="white lighten-1 black--text">
        <v-row class="pa-16">
            <v-col cols="12" md="3" lg="3" xl="3" class="pl-lg-10 pl-xl-10 pl-md-10 pb-12">
                <v-row class="pb-10">
                    <v-card-text class="black--text pt-0 sectionHeadings">
                        راه های ارتباطی
                    </v-card-text>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6" lg="6" xl="6" class="sectionBodyRowTitle">
                        <strong>شماره فروشگاه :</strong>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" xl="6" class="sectionBodyRowDesc">
                        <a href="tel:+982122145411" style="text-decoration: none;">
                            <span>021 - 22145411</span>
                        </a>
                    </v-col>
                </v-row>
                <v-row class="pb-6">
                    <v-col cols="12" md="6" lg="6" xl="6" class="sectionBodyRowTitle">
                        <strong>شماره پشتیبانی :</strong>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" xl="6" class="sectionBodyRowDesc">
                        <a href="tel:+989120935606" style="text-decoration: none;">
                            <span>0912 - 0935 - 606</span>
                        </a>
                    </v-col>
                </v-row>
                <v-divider class="hidden-md-and-up"></v-divider>
            </v-col>

            <v-divider vertical class="hidden-sm-and-down"></v-divider>

            <v-col cols="12" md="3" lg="3" xl="3"
                   class="pr-lg-10 pr-xl-10 pr-md-10 pl-lg-10 pl-xl-10 pl-md-10 pb-12 ">
                <v-row class="pb-10">
                    <v-card-text class="black--text pt-0 sectionHeadings">
                        لینک ها
                    </v-card-text>
                </v-row>
                <v-row class="listAlign pb-6">
                    <v-list>
                        <v-list-item class="pb-3"
                                     v-for="(item, i) in items" :key="i">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <a :href="item.link" target="_blank" style="text-decoration: none;">
                                        <strong class="sectionBodyRowTitle">{{item.text}}</strong>
                                    </a>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-row>
                <v-divider class="hidden-md-and-up"></v-divider>
            </v-col>

            <v-divider vertical class="hidden-sm-and-down"></v-divider>

            <v-col cols="12" md="6" lg="6" xl="6" class="pr-lg-10 pr-xl-10 pr-md-10">
                <v-row class="pb-16">
                    <v-card-text class="black--text pt-0 sectionHeadings">
                        شبکه های اجتماعی
                    </v-card-text>
                </v-row>
                <v-row class="justify-space-around">
                    <v-btn v-for="(icon,j) in icons" :key="j"
                           class="ma-4 " fab color="textBlack"
                           :href="icon.link" target="_blank">
                        <v-icon size="40px" color="white" v-text="icon.text">
                        </v-icon>
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-card-text class="black--text copyRightText text-center">
            {{ new Date().getFullYear() }} — <strong>تمام حقوق برای
            <a href="https://talamin.com" target="_blank">
                فروشگاه آنلاین طلامین
            </a>
            محفوظ می باشد ©</strong>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "Footer",
        data: () => ({
            icons: [
                {
                    text: 'mdi-facebook',
                    link: '/'
                },
                {
                    text: 'mdi-twitter',
                    link: '/'
                },
                {
                    text: 'mdi-whatsapp',
                    link: '/'
                },
                {
                    text: 'mdi-instagram',
                    link: 'https://www.instagram.com/talamingallery/'
                },
                {
                    text: 'fa-telegram',
                    link: '/'
                },
            ],
            items: [
                {text: 'صفحه اصلی فروشگاه', link: 'https://talamin.com'},
                {text: 'لینک های دسترسی طلامین', link: 'https://zil.ink/talamingalleryshop'},
            ],
        }),
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Harmattan');

    .copyRightText {
        font-family: Harmattan;
        font-weight: 400;
        font-size: 17px;
    }

    .sectionHeadings {
        font-family: Harmattan;
        font-weight: 600;
        font-size: 30px;
    }

    .sectionBodyRowTitle {
        font-family: Harmattan;
        font-size: 20px;
    }

    .sectionBodyRowDesc {
        font-family: Harmattan;
        font-weight: 400;
        font-size: 20px;
        direction: ltr;
    }

    @media only screen and (max-device-width: 400px) {
        .sectionHeadings {
            font-family: Harmattan;
            text-align: center;
            font-weight: 600;
            font-size: 30px;
        }

        .sectionBodyRowTitle {
            font-family: Harmattan;
            text-align: center;
            font-size: 20px;
        }

        .sectionBodyRowDesc {
            font-family: Harmattan;
            font-weight: 400;
            font-size: 20px;
            direction: ltr;
            text-align: center;
        }

        .listAlign {
            justify-content: center;
            text-align: center;
        }

        .socialIcons {
            justify-content: center;
        }
    }

</style>
