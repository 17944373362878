<template>
    <v-app style="background-color: #f8f8f8">
        <Header/>

        <v-main>
            <Calculator/>
        </v-main>

        <v-footer >
            <Footer/>
        </v-footer>
    </v-app>
</template>

<script>
    import Calculator from "./components/Calculator";
    import Header from "./components/Header";
    import Footer from "./components/Footer";

    export default {
        name: 'App',

        components: {
            Footer,
            Header,
            Calculator,
        },
        created() {
            this.$session.clear();
        }
    };
</script>
