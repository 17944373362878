import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';
import 'font-awesome/css/font-awesome.min.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: ['mdi', 'fa4']
    },
    rtl: true,
    theme: {
        themes: {
            light: {
                primary: '#0b2c08',
                black: '#020101',
                textBlack: '#1E1F26',
                backgroundOne: '#F8F8F8',
                backGroundTwo: '#FCF5ED',
                backgroundThree: '#BBCDBD',
                mellowOne: '#FFE9DC',
                mellowTwo: '#E0A899',
            },
        },
    },
});
