import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import VueSession from 'vue-session'
import VueRouter from 'vue-router'

Vue.use(VueSession);
Vue.use(VueRouter);


Vue.config.productionTip = false;

new Vue({
    vuetify,
    render: h => h(App)
}).$mount('#app')
